<template>
    <div>
    <v-card  style="overflow-y: hidden">
        <v-toolbar flat dense>
            <v-toolbar-title>
                Bulk Update Containers
            </v-toolbar-title>
            <v-spacer></v-spacer>
        <v-btn color="tertiary" @click="$emit('updateContainers',containerItemBulkEdit )">UPDATE</v-btn>
            <v-btn color="primary" icon @click="$emit('close'), beforeClose"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                    <v-card height="35vh" tile flat >
                    <v-data-table :items="chosenContainers" :headers="containerHeadersEdit"
                        dense hide-default-footer fixed-header
                        disable-pagination>
                        <template v-slot:[`item.containerNo`]="{ item }">
                            <div v-if="item.containerNo"  @click="containerNo = item.containerNo, containerNoDialog = true" style="cursor: pointer">
                                {{ item.containerNo }}
                            </div>
                        </template>
                        <template v-slot:[`item.sealNo`]="{ item }">
                            <div v-if="item.sealNo" @click="sealNo = item.sealNo, sealNoDialog = true" style="cursor: pointer">
                                {{ item.sealNo }}
                            </div>
                        </template>
                        <template v-slot:[`item.features`]="{ item }">
                            <v-row class="my-1" justify="center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip
                                            :color="item.bookingContainerItem.gensetRequired ? '#c4c166' : ''"
                                            v-on="on" x-small class="mx-1">
                                            <v-icon small>bolt</v-icon>
                                        </v-chip>
                                    </template>
                                    <span>Genset: 
                                        <span v-if="item.bookingContainerItem.gensetRequired"> Required</span>
                                        <span v-else> Not Required</span></span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip
                                            :color="item.bookingContainerItem.controlledAtmosphere ? '#2383de' : ''"
                                            v-on="on" x-small class="mx-1">
                                            <v-icon small>thermostat_auto</v-icon>
                                        </v-chip>
                                    </template>
                                    <span>Controlled Atmosphere: {{
                                        item.bookingContainerItem.controlledAtmosphere ?
                                        ' Required' : ' Not Required' }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip
                                            :color="item.bookingContainerItem.sensorDevice ? 'teal' : ''"
                                            v-on="on" x-small class="mx-1">
                                            <v-icon small>router</v-icon>
                                        </v-chip>
                                    </template>
                                    <span>Monitoring Device:
                                        <span v-if="item.bookingContainerItem.sensorDevice">
                                            {{
                                                item.bookingContainerItem.sensorDeviceType }}
                                            {{ item.bookingContainerItem.sensorService ? ' with ' +
                                                item.bookingContainerItem.sensorService + ' service' :
                                                'without a service' }}
                                        </span>
                                        <span v-else>None</span>
                                    </span>
                                </v-tooltip>
                            </v-row>
                        </template>
                        <template v-slot:[`item.regimeCode`]="{ item }">
                            <div v-if="item">{{ booking.regimeCode }}</div>
                        </template>
                        <template v-slot:[`item.products`]="{ item }">
                            <ul style="cursor:pointer">
                                <li v-for="product in item.containerProducts"
                                
                                    :key="product.id">
                                    {{ product.product.name }}
                                </li>
                            </ul>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                                    <v-chip small v-if="item.status" :color="matchColor(item.status)">
                                        <v-icon class="mr-2" color="yellow" small v-if="item.status == 'ISSUE'">
                                            warning
                                        </v-icon>
                                        {{item.status}} 
                                    </v-chip>
                                </template>
                    </v-data-table>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" class="pl-0">
                    <v-card style="background-color: var(--v-greyRaised-lighten2) !important" flat height="45vh">
                        <v-card-text style="height: 100%">
                            <v-row style="height: 100%" no-gutters>
                                <!-- LOADING POINTS -->
                                <v-col cols="12" sm="5" class="pb-0 pr-2">
                                    <v-card class="pt-1" flat style="background-color: var(--v-toolbar-lighten2) !important; contain:content; height: 100%">
                                        <v-list dense class="pa-0">
                                            <v-subheader style="font-size: 16px"> 
                                                <v-icon class="mr-2" color="secondary">location_on</v-icon>
                                                Loading Points
                                            </v-subheader>
                                            <v-divider></v-divider>
                                            <v-timeline dense class="localClass">
                                                <v-timeline-item right dot :color="containerItemBulkEdit.emptyPickup ? 'orange' : 'grey'"
                                                    small class="my-0 py-0">
                                                    <v-list-item class="text-left my-0 py-0"
                                                        style="font-size: 14px; color: grey"
                                                        @click="addLoadingPointDense('emptyLoadingPoint')">
                                                        <v-list-item-content>
                                                            <v-list-item-title v-if="!containerItemBulkEdit.emptyPickup">No Empty
                                                                Pickup Point</v-list-item-title>
                                                            <v-list-item-title v-else>{{ containerItemBulkEdit.emptyPickup
                                                            }}</v-list-item-title>
                                                            <v-list-item-subtitle style="font-size: 12px; color: grey; white-space: inherit" 
                                                                v-if="containerItemBulkEdit.emptyPickupPoi">
                                                                {{ containerItemBulkEdit.emptyPickupPoi.address }}
                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle v-if="containerItemBulkEdit.emptyPickup"
                                                                style="font-weight: bold; white-space: inherit">Empty Pickup Point
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-btn v-if="containerItemBulkEdit.emptyPickup" icon color="primary" small @click="removePoi('emptyLoadingPoint')"><v-icon>close</v-icon></v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                    <template v-slot:opposite>
                                                        <v-list-item class="text-right my-0 py-0" @click="addLoadingSlotModal('emptyLoadingPoint')">
                                                            <v-list-item-content>
                                                                <v-list-item-title style="white-space: inherit;" v-if="containerItemBulkEdit.emptyDateSlot">{{formatDate(containerItemBulkEdit.emptyDateSlot).dayOfWeek + ',' + ' ' + formatDate(containerItemBulkEdit.emptyDateSlot).shorterDate}}</v-list-item-title>
                                                                <v-list-item-subtitle style="font-size: 12px; white-space: inherit" v-if="containerItemBulkEdit.emptyTimeSlot"><b>{{containerItemBulkEdit.emptyTimeSlot}}</b></v-list-item-subtitle>
                                                                <v-list-item-subtitle style="font-size: 12px; color: grey" v-if="!containerItemBulkEdit.emptyDateSlot && !containerItemBulkEdit.emptyTimeSlot">
                                                                        No Loading Slot
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-timeline-item>
                                                <v-timeline-item dot right
                                                    :color="containerItemBulkEdit.firstLoadingPoint ? 'blue' : 'grey'" small
                                                    class="my-0 py-0">
                                                    <v-list-item class="text-left my-0 py-0"
                                                        style="font-szie: 14px; color: grey"
                                                        @click="addLoadingPointDense('firstLoadingPoint')">
                                                        <v-list-item-content>
                                                            <v-list-item-title v-if="!containerItemBulkEdit.firstLoadingPoint">No
                                                                Loading Point</v-list-item-title>
                                                            <v-list-item-title v-else>{{ containerItemBulkEdit.firstLoadingPoint
                                                            }}</v-list-item-title>
                                                            <v-list-item-subtitle style="font-size: 12px; color: grey; white-space: inherit"
                                                                v-if="containerItemBulkEdit.firstLoadingPointPoi">
                                                                {{ containerItemBulkEdit.firstLoadingPointPoi.address }}
                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle v-if="containerItemBulkEdit.firstLoadingPoint"
                                                                style="font-weight: bold; white-space: inherit">Loading
                                                                Point</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-btn v-if="containerItemBulkEdit.firstLoadingPoint" icon color="primary" small @click="removePoi('firstLoadingPoint')"><v-icon>close</v-icon></v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                    <template v-slot:opposite>
                                                        <v-list-item class="text-right my-0 py-0" @click="addLoadingSlotModal('firstLoadingPoint')">
                                                            <v-list-item-content>
                                                                <v-list-item-title style="white-space: inherit;" v-if="containerItemBulkEdit.firstDateSlot">{{formatDate(containerItemBulkEdit.firstDateSlot).dayOfWeek + ',' + ' ' + formatDate(containerItemBulkEdit.firstDateSlot).shorterDate}}</v-list-item-title>
                                                                <v-list-item-subtitle style="font-size: 12px; white-space: inherit" v-if="containerItemBulkEdit.firstTimeSlot"><b>{{containerItemBulkEdit.firstTimeSlot}}</b></v-list-item-subtitle>
                                                                <v-list-item-subtitle style="font-size: 12px; color: grey" v-if="!containerItemBulkEdit.firstDateSlot && !containerItemBulkEdit.firstTimeSlot">
                                                                        No Loading Slot
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-timeline-item>
                                                <v-timeline-item dot right
                                                    :color="containerItemBulkEdit.secondLoadingPoint ? 'blue' : 'grey'" small
                                                    class="my-0 py-0" >
                                                    <v-list-item class="text-left my-0 py-0"
                                                        style="font-szie: 14px; color: grey"
                                                        @click="addLoadingPointDense('secondLoadingPoint')">
                                                        <v-list-item-content>
                                                            <v-list-item-title v-if="!containerItemBulkEdit.secondLoadingPoint">No
                                                                2nd Loading Point</v-list-item-title>
                                                            <v-list-item-title v-else>{{ containerItemBulkEdit.secondLoadingPoint
                                                            }}</v-list-item-title>
                                                            <v-list-item-subtitle style="font-size: 12px; color: grey; white-space: inherit"
                                                                v-if="containerItemBulkEdit.secondLoadingPointPoi">
                                                                {{ containerItemBulkEdit.secondLoadingPointPoi.address }}
                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle v-if="containerItemBulkEdit.secondLoadingPoint"
                                                                style="font-weight: bold; white-space: inherit">Loading
                                                                Point</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-btn v-if="containerItemBulkEdit.secondLoadingPoint" icon color="primary" small @click="removePoi('secondLoadingPoint')"><v-icon>close</v-icon></v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                    <template v-slot:opposite>
                                                        <v-list-item class="text-right my-0 py-0" @click="addLoadingSlotModal('secondLoadingPoint')">
                                                            <v-list-item-content>
                                                                <v-list-item-title style="white-space: inherit;" v-if="containerItemBulkEdit.secondDateSlot">{{formatDate(containerItemBulkEdit.secondDateSlot).dayOfWeek + ',' + ' ' + formatDate(containerItemBulkEdit.secondDateSlot).shorterDate}}</v-list-item-title>
                                                                <v-list-item-subtitle style="font-size: 12px; white-space: inherit" v-if="containerItemBulkEdit.secondTimeSlot"><b>{{containerItemBulkEdit.secondTimeSlot}}</b></v-list-item-subtitle>
                                                                <v-list-item-subtitle style="font-size: 12px; color: grey" v-if="!containerItemBulkEdit.secondDateSlot && !containerItemBulkEdit.secondTimeSlot">
                                                                        No Loading Slot
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-timeline-item>
                                                <v-timeline-item dot right
                                                    :color="containerItemBulkEdit.dropOffPoint ? 'success' : 'grey'" small
                                                    class="my-0 py-0">
                                                    <v-list-item class="text-left my-0 py-0"
                                                        style="font-size: 14px; color: grey; "
                                                        @click="addLoadingPointDense('dropOffPoint')">
                                                        <v-list-item-content>
                                                            <v-list-item-title v-if="!containerItemBulkEdit.dropOffPoint">No Drop
                                                                Off Point</v-list-item-title>
                                                            <v-list-item-title v-else>{{ containerItemBulkEdit.dropOffPoint
                                                            }}</v-list-item-title>
                                                            <v-list-item-subtitle style="font-size: 12px; color: grey; white-space: inherit"
                                                                v-if="containerItemBulkEdit.dropOffPointPoi">
                                                                {{ containerItemBulkEdit.dropOffPointPoi.address }}
                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle v-if="containerItemBulkEdit.dropOffPoint"
                                                                style="font-weight: bold; white-space: inherit">Drop Off
                                                                Point</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-btn v-if="containerItemBulkEdit.dropOffPoint" icon color="primary" small @click="removePoi('dropOffPoint')"><v-icon>close</v-icon></v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                    <template v-slot:opposite>
                                                        <v-list-item class="text-right my-0 py-0" style="font-size: 12px" @click="addLoadingSlotModal('dropOffPoint')">
                                                            <v-list-item-content>
                                                                <v-list-item-title style="white-space: inherit;" v-if="containerItemBulkEdit.dropOffDateSlot">{{formatDate(containerItemBulkEdit.dropOffDateSlot).dayOfWeek + ',' + ' ' + formatDate(containerItemBulkEdit.dropOffDateSlot).shorterDate}}</v-list-item-title>
                                                                <v-list-item-subtitle style="font-size: 12px; white-space: inherit" v-if="containerItemBulkEdit.dropOffTimeSlot"><b>{{containerItemBulkEdit.dropOffTimeSlot}}</b></v-list-item-subtitle>
                                                                <v-list-item-subtitle style="font-size: 12px; color: grey" v-if="!containerItemBulkEdit.dropOffDateSlot && !containerItemBulkEdit.dropOffTimeSlot">
                                                                        No Loading Slot
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-timeline-item>
                                            </v-timeline>
                                        </v-list>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="3" class="pb-0 px-2">
                                    <!-- PRODUCTS --> 
                                    <v-list class="mt-2" dense subheader v-if="containerItemBulkEdit.bookingCargoItems">
                                        <span class="pl-3" style="font-size: 16px"><b>Products </b> </span>
                                        <v-btn x-small class="mb-1" color="primary" @click="productView = true" icon><v-icon>add_circle_outline</v-icon></v-btn>
                                        <v-list-item v-if="containerItemBulkEdit.containerProducts.length == 0">
                                            <v-list-item-content class="text-center">
                                                <span style="color: grey">No products listed</span>
                                            </v-list-item-content></v-list-item>
                                        <v-list-item v-for="cargo in containerItemBulkEdit.containerProducts" :key="cargo.index">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ cargo.product.name }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="text-wrap">
                                                    {{ cargo.hsCode }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="cargo.weight || cargo.volume" class="text-wrap">
                                                    {{ cargo.weight }} Kg - {{ cargo.volume }} CBM
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-row justify="center">
                                                    <v-btn icon small @click="deleteCargoItem(cargo)" color="red"><v-icon
                                                            small>close</v-icon></v-btn>
                                                </v-row>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="12" sm="4" class="pb-0 pl-2">
                                    <!-- STATUS CHANGE -->
                                    <v-list-item dense>
                                        <v-list-item-action>
                                            <v-icon>new_releases</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content class="text-wrap">
                                            <v-list-item-title> Status </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action class="my-1">
                                            <v-menu  offset-y style="min-width: 8vw;" rounded :key="statusMenuKey">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-chip v-if="containerItemBulkEdit.status" :color="matchColor(containerItemBulkEdit.status)">
                                                        <v-icon class="mr-2" color="yellow" small v-if="containerItemBulkEdit.status == 'ISSUE'">
                                                            warning
                                                        </v-icon>
                                                        {{containerItemBulkEdit.status}} 
                                                        <span v-if="(containerItemBulkEdit.status == 'AT' || containerItemBulkEdit.status == 'EN ROUTE TO') && containerItemBulkEdit.statusLocation" style="margin-left: 5px">
                                                            {{containerItemBulkEdit.statusLocation.value }}</span> 
                                                        <v-btn v-bind="attrs" v-on="on" icon><v-icon small>expand_more</v-icon></v-btn>
                                                    </v-chip>
                                                    <v-chip v-else color="grey">
                                                        STATUS
                                                        <v-btn v-bind="attrs" v-on="on" icon><v-icon small>expand_more</v-icon></v-btn>
                                                    </v-chip>
                                                </template>
                                                <v-list dense color="greyBase" rounded>
                                                    <v-menu offset-y rounded :key="statusMenuKey">
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-list-item
                                                            @click="containerItemBulkEdit.status = status.state"
                                                            v-on="status.state == 'AT' || status.state == 'EN ROUTE TO' ? on : ''"
                                                            v-for="status in statuses"
                                                            :key="status.id"
                                                            >
                                                            <div class="colorBlock" :style="{'background-color': status.color }"></div> <small style="margin-left: 10px;"><b>{{ status.state }}</b></small>
                                                            <v-list-item-action v-if="status.state == 'EN ROUTE TO'" style="position: absolute; right: 0;" ><v-btn @click="containerItemBulkEdit.status = status.state" v-bind="attrs" v-on="on" icon><v-icon small>expand_more</v-icon></v-btn></v-list-item-action>
                                                            <v-list-item-action v-if="status.state == 'AT' " style="position: absolute; right: 0;" ><v-btn @click="containerItemBulkEdit.status = status.state" v-bind="attrs" v-on="on" icon><v-icon small>expand_more</v-icon></v-btn></v-list-item-action>
                                                        </v-list-item>
                                                    </template>
                                                    <v-list dense color="greyBase" rounded>
                                                        <v-list-item
                                                        v-for="(location, index) in statusLocations"
                                                        :key="index"
                                                        @click="containerItemBulkEdit.statusLocation = location, statusMenuKey++"
                                                        >
                                                        <small><b>{{ location.name }}</b></small>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                </v-list>
                                            </v-menu>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <!-- GENSET REQUIRED -->
                                    <v-list-item dense>
                                        <v-list-item-action>
                                            <v-icon>bolt</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content class="text-wrap">
                                            <v-list-item-title> Genset Required </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-switch v-model="containerItemBulkEdit.gensetRequired" color="success"></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <!-- CONTROLLED ATMOSPHERE -->
                                    <v-list-item dense>
                                        <v-list-item-action>
                                            <v-icon>thermostat_auto</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-wrap">
                                                Controlled Atmosphere
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-switch v-model="containerItemBulkEdit.controlledAtmosphere" color="success"></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>    
                                    <v-row no-gutters>
                                        <!-- MONITORING AND METHOD -->
                                        <v-col cols="12" sm="6" class="pr-1">
                                            <v-select label="Monitoring Service" placeholder="Select a Monitoring Service" persistent-placeholder  hide-details outlined dense class="py-2" :items="['iCheck','TempCheck', 'TempCheckPLUS', 'Port Monitoring']" v-model="containerItemBulkEdit.sensorService"></v-select>
                                            <v-select label="Method" placeholder="Select a Method" persistent-placeholder  hide-details outlined dense class="py-2" :items="['Method 1', 'Method 2']" v-model="containerItemBulkEdit.verificationMethod"></v-select>
                                        </v-col>
                                        <!-- TEMP DEVICE AND REGIME CODE -->
                                        <v-col cols="12" sm="6" class="pl-1">
                                            <v-select label="Temperature Device Type" placeholder="Select a Temperature Device" persistent-placeholder  hide-details outlined dense class="py-2" :items="['RF TEMPTALE','USB TEMPTALE','GEO EAGLE TEMPTALE','MOST TEMPTALE','2G GEO TEMPTALE','3G GEO TEMPTALE','4G GEO TEMPTALE','2G TIVE','5G TIVE']" v-model="containerItemBulkEdit.sensorDeviceType"></v-select>
                                            <v-autocomplete label="Regime Code" placeholder="Select a Regime Code" persistent-placeholder outlined dense class="pb-1 pt-2"
                                                v-model="containerItemBulkEdit.regimeCode" :hint="containerItemBulkEdit.regimeCode ? containerItemBulkEdit.regimeCode.recipeDescription : ''" persistent-hint return-object :items="regimeCodes" item-text="name"
                                                item-value="code" >
                                                <template v-slot:selection="data">
                                                    <span v-if="data.item">
                                                        {{ data.item.code }}
                                                    </span>
                                                </template>
                                                <template v-slot:item="data">
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ data.item.code }}</v-list-item-title>
                                                        <v-list-item-subtitle class="text-wrap">{{
                                                            data.item.recipeDescription
                                                        }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <!-- COMMENTS -->
                                    <v-textarea v-model="containerItemBulkEdit.comment" label="Comments" outlined hide-details
                                        rows="3"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    
    <!------------------------------------------ CHANGE / UPDATE PRODUCTS ------------------------------------------>
    <v-dialog v-model="productView" width="400px">
        <v-card>
            <Products @close="productView = false" @chosenProduct="updateContainerProduct" />
        </v-card>
    </v-dialog>

    <!------------------------------------------ LOADING DATE/TIME SLOTS ------------------------------------------>
    <v-dialog v-model="loadingTimeModal" width="480px">
            <v-card flat>
                <v-toolbar flat>
                    <v-toolbar-title>
                        <span v-if="loadingPointItem.type == 'emptyLoadingPoint'">Empty Pickup Time Slot</span>
                        <span v-else-if="loadingPointItem.type == 'firstLoadingPoint'">1st Loading Point Time Slot </span>
                        <span v-else-if="loadingPointItem.type == 'secondLoadingPoint'">2nd Loading Point Time Slot</span>
                        <span v-else-if="loadingPointItem.type == 'dropOffPoint'">Drop Off Time Slot</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon color="primary" @click="loadingTimeModal = false"><v-icon>close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                <v-row no-gutters >
                     <!-- DATE PICKER -->
                    <v-col cols="12" sm="12" class="px-1">
                        <v-menu
                          ref="dateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="formatDate(dateSlot).fullDate"
                                  outlined
                                  dense
                                  label="Loading Date"
                                  prepend-inner-icon="event"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="dateSlot"
                              @change="saveTheDate"
                              :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <!-- TIME PICKER -->
                    <v-col cols="12" sm="12" class="px-1">
                        <v-menu
                          ref="timeMenu"
                          :close-on-content-click="false"
                          :return-value.sync="timeSlot"
                          transition="scale-transition"
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="timeSlot"
                              label="Loading Time"
                              prepend-inner-icon="schedule"
                              readonly
                              dense
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-model="timeSlot"
                            full-width
                            format="24hr"
                            @change="saveTheTime"
                          ></v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" class="px-1">
                        <v-text-field
                              v-model="passCodeSlot"
                              label="Passcode (Optional)"
                              persistent-placeholder
                              placeholder="Add Passcode for Entrance at Loading Point"
                              prepend-inner-icon="password"
                              hide-details
                              dense
                              outlined
                            ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" class="pt-4 d-flex justify-center align-center">
                        <v-btn @click="setLoadingSlots" color="tertiary">Accept</v-btn>
                    </v-col>
                </v-row>
                </v-card-text>
            </v-card>
    </v-dialog>
</div>
</template>

<script>
import dateFormat from 'dateformat';
import Products from "./ProductsDialog.vue"
export default {
    components: {
        Products
    },
    props: [
        "chosenContainers",
    ],
    data: () => ({
        poiSearch: '',
        timeSlot: null,
        passCodeSlot: null,
        poiModalDense: false,
        loadingTimeModal: false,
        productView: false,
        loadingFunctions: false,
        loadingPointItem: {},
        regimeCodes: [],
        poiFunctions: [],
        containerHeadersEdit: [
            {
                value: 'ctoNo',
                text: 'CTO No.',
                align: 'center',
                show: true
            },
            {
                value: 'containerTypeCode',
                text: 'Type',
                align: 'center',
                show: true
            },
            {
                value: 'verificationMethod',
                text: 'Method',
                align: 'center',
                show: true
            },
            {
                value: 'features',
                text: 'Features',
                align: 'center',
                show: true,
                sortable: false
            },
            {
                value: 'regimeCode',
                text: 'Regime',
                align: 'center',
                show: true
            },
            {
                value: 'products',
                text: 'Products',
                align: 'center',
                show: true,
                sortable: false
            },
            {
                value: 'emptyPickup',
                text: 'Empty',
                align: 'center',
                show: true
            },
            {
                value: 'firstLoadingPoint',
                text: 'LP-1',
                align: 'center',
                show: true
            },
            {
                value: 'secondLoadingPoint',
                text: 'LP-2',
                align: 'center',
                show: true
            },
            {
                value: 'dropOffPoint',
                text: 'Drop-Off',
                align: 'center',
                show: true
            },
            {
                value: 'status',
                text: 'Status',
                align: 'center',
                show: true
            }
        ],
        statuses: [
            {id:4,  state:'AT',              color:'#006bc3', location: null},
            {id:3,  state:'ASSIGNED',        color:'#0094db', location: null},
            {id:8,  state:'COMPLETED',       color:'#008a61', location: null},
            {id:6,  state:'DONE LOADING',    color:'#2cc194', location: null},
            {id:5,  state:'EN ROUTE TO',     color:'#ccb50a', location: null},
            {id:7,  state:'STOPPED',         color:'#e55000', location: null},
            {id:9,  state:'CANCELLED',       color:'#ff6666', location: null},
            {id:10, state:'ISSUE',           color:'#7f0000', location: null},
            {id:2,  state:'PENDING',         color:'#657583', location: null},
            {id:1,  state:'TO BE ALLOCATED', color:'#b4bbc1', location: null},
        ],
        statusLocations: [
            {name:'LOADING POINT 1', value: 'LP1'},
            {name:'LOADING POINT 2' , value: 'LP2'},
            {name:'EMPTY DEPOT' , value: 'EMPTY'},
            {name:'PORT OF LOADING' , value: 'POL'},
            {name:'DROP OFF' , value: 'DROPOFF'},
            {name:'YARD' , value: 'YARD'},
            {name:'FUEL STATION' , value: 'FUEL STATION'},
        ],
        statusFilters: {
            at: false,
            assigned: false,
            completed: false,
            'done Loading': false,
            'en Route To': false,
            stopped: false,
            cancelled: false,
            issue: false,
            pending: false,
            'to Be Allocated': false,
        },
        statusMenuKey: 0,
        loadingPointKey: 0,
    }),
    async created() {
        this.regimeCodes = await this.$API.getRegimeCodes();
        this.getPoiFunctions();
    },
    computed: {

    },
    watch: {
        'poiSearch' : {
            immediate: true,
            handler(val) {
                if (val) {
                    this.searchPois(val)
                }
            }
        },
    },
    methods: {
        beforeClose() {
            // CHECK IF ANY CHANGES HAVE BEEN MADE BEFORE CLOSING
            console.log('placeholder')
        },

////////////////////////////////////// LOADING POINTS //////////////////////////////////////
        addLoadingPointDense(type) {
            switch (type) {
                case 'emptyLoadingPoint':
                    this.poiFunctions.forEach(item => {
                        if (item.name == 'Container Depot') {
                            item.selected = true
                        } else {
                            item.selected = false
                        }
                    })
                    break;
                case 'firstLoadingPoint':
                    this.poiFunctions.forEach(item => {
                        if(item.name == 'Commercial Cold Store' || item.name == 'Pack-House (Off-Farm)' || item.name == 'Pack-House (On-Farm)') {
                            item.selected = true;
                        } else {
                            item.selected = false
                        }
                    })
                    break;
                case 'secondLoadingPoint':
                this.poiFunctions.forEach(item => {
                        if(item.name == 'Commercial Cold Store' || item.name == 'Pack-House (Off-Farm)' || item.name == 'Pack-House (On-Farm)') {
                            item.selected = true;
                        } else {
                            item.selected = false
                        }
                    })
                    break;
                case 'dropOffPoint':
                    this.poiFunctions.forEach(item => {
                        if(item.name == 'Port' || item.name == 'Port Terminal') {
                            item.selected = true;
                        } else {
                            item.selected = false
                        }
                    })
                    break;
                default:
                    break;
            } 
            this.loadingPointItem = {
                type: type
            };
            this.poiModalDense = true;
        },
        addLoadingSlotModal(type) {
            this.loadingPointItem = {
                type: type,
            }
            this.loadingTimeModal = true
        },
        async getPoiFunctions() {
            this.loadingFunctions = true;
            this.poiFunctions = await this.$API.getPoiFunctions();
            if(this.poiFunctions.length > 0) {
                this.loadingFunctions = false;
            } else {
                this.loadingFunctions = false;
                this.$message({
                    message: 'Error loading the Poi Functions. Please try again later.',
                    type: 'warning',
                });
            }
        },
        removePoi(type) {
            switch(type) {
                case 'emptyLoadingPoint':
                this.containerItemBulkEdit.emptyPickup = null
                this.containerItemBulkEdit.emptyPickupPoi = null
                this.containerItemBulkEdit.emptyPickupPoiId = null
                break;
                case 'firstLoadingPoint':
                this.containerItemBulkEdit.firstLoadingPoint = null
                this.containerItemBulkEdit.firstLoadingPointPoi = null
                this.containerItemBulkEdit.firstLoadingPointPoiId = null
                break;
                case 'secondLoadingPoint':
                this.containerItemBulkEdit.secondLoadingPoint = null
                this.containerItemBulkEdit.secondLoadingPointPoi = null
                this.containerItemBulkEdit.secondLoadingPointPoiId = null
                break;
                case 'dropOffPoint':
                this.containerItemBulkEdit.dropOffPoint = null
                this.containerItemBulkEdit.dropOffPointPoi = null
                this.containerItemBulkEdit.dropOffPointPoiId = null
                break;
                default:
                break;
            }
              this.poiModalDense = false
        },
        setLoadingPoint(point) {
            if (this.loadingPointItem.type == 'emptyLoadingPoint') {
                this.containerItemBulkEdit.emptyPickup = point.name
                this.containerItemBulkEdit.emptyPickupPoi = point
                this.containerItemBulkEdit.emptyPickupPoiId = point.id
            } else if (this.loadingPointItem.type == 'firstLoadingPoint') {
                this.containerItemBulkEdit.firstLoadingPoint = point.name
                this.containerItemBulkEdit.firstLoadingPointPoi = point
                this.containerItemBulkEdit.firstLoadingPointPoiId = point.id
            } else if (this.loadingPointItem.type == 'secondLoadingPoint') {
                this.containerItemBulkEdit.secondLoadingPoint = point.name
                this.containerItemBulkEdit.secondLoadingPointPoi = point
                this.containerItemBulkEdit.secondLoadingPointPoiId = point.id
            } else if (this.loadingPointItem.type == 'dropOffPoint') {
                this.containerItemBulkEdit.dropOffPoint = point.name
                this.containerItemBulkEdit.dropOffPointPoi = point
                this.containerItemBulkEdit.dropOffPointPoiId = point.id
            }
            this.poiSearch = ''
            this.loadingPointKey++
            this.poiModalDense = false
            this.loadingPointItem = {}

        },
        setLoadingSlots() {
            switch(this.loadingPointItem.type) {
                case 'emptyLoadingPoint':
                    this.containerItemBulkEdit.emptyDateSlot = this.dateSlot ? this.dateSlot : null
                    this.containerItemBulkEdit.emptyTimeSlot = this.timeSlot ? this.timeSlot : null
                    this.containerItemBulkEdit.emptyPasscode = this.passCodeSlot ? this.passCodeSlot : null
                    break;
                case 'firstLoadingPoint':
                    this.containerItemBulkEdit.firstDateSlot  = this.dateSlot ? this.dateSlot : null
                    this.containerItemBulkEdit.firstTimeSlot  = this.timeSlot ? this.timeSlot : null
                    this.containerItemBulkEdit.firstPasscode  = this.passCodeSlot ? this.passCodeSlot : null
                    break;
                case 'secondLoadingPoint':
                    this.containerItemBulkEdit.secondDateSlot = this.dateSlot ? this.dateSlot : null
                    this.containerItemBulkEdit.secondTimeSlot = this.timeSlot ? this.timeSlot : null
                    this.containerItemBulkEdit.secondPasscode = this.passCodeSlot ? this.passCodeSlot : null
                    break;
                case 'dropOffPoint':
                    this.containerItemBulkEdit.dropOffDateSlot = this.dateSlot ? this.dateSlot : null
                    this.containerItemBulkEdit.dropOffTimeSlot = this.timeSlot ? this.timeSlot : null
                    this.containerItemBulkEdit.dropOffPasscode = this.passCodeSlot ? this.passCodeSlot : null
                    break;
                default:
                    break;
            }
            this.timeSlot = null
            this.passCodeSlot = null
            this.loadingPointItem = {}
            this.loadingTimeModal = false
        },
        async searchPois(val) {
            if (this.poiSearchLoading) {
                clearTimeout(this.poiSearchTimeout);
            }
            if (this.poiRequest) {
                this.poiRequest.abort()
            }
            this.poiParams.filter = this.poiFunctions.filter(item => item.selected).map(item => item.name)
            this.poiParams.search = val
            console.log(this.poiParams)
            if (val && val.length > 1) {
            this.poiSearchLoading = true;
            this.poiSearchTimeout = setTimeout(async () => {
                this.poiRequest = new AbortController();
                const signal = this.poiRequest.signal
                this.pointsOfInterest = await this.$API.getPois(
                    {
                        params: this.poiParams,
                        signal
                    }
                );
                this.poiSearchLoading = false;
            }, 750);
            } else {
                clearTimeout(this.poiSearchTimeout);
                this.poiSearchLoading = false;
            }
        },
////////////////////////////////////// PRODUCTS / CARGO //////////////////////////////////////
        deleteCargoItem(item) {
              let find = this.containerItemBulkEdit.bookingCargoItems.find(x => x.hsCode === item.hsCode)
              if(find) {
                  let index = this.containerItemBulkEdit.bookingCargoItems.indexOf(find)
                  this.containerItemBulkEdit.bookingCargoItems.splice(index, 1)
              }
        },
        updateContainerProduct(item) {
          if(this.bulkUpdateCard) {
              if(this.containerItemBulkEdit.bookingCargoItems.length > 0) {
                  this.containerItemBulkEdit.bookingCargoItems.push(item)
              } else {
                  this.containerItemBulkEdit.bookingCargoItems = [item]
              }
          }
          this.productView = false
          console.log(item)
        },

////////////////////////////////////// STATUS //////////////////////////////////////

        matchColor(itemStatus) {
        let hex = this.statuses.find(status => status.state === itemStatus).color
        return hex
        },

////////////////////////////////////// TOOLS //////////////////////////////////////
        saveTheDate (date) {
            this.$refs.dateMenu.save(date)
        },
        saveTheTime (time) {
          this.$refs.timeMenu.save(time)
        },
        formatDateTime(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd mmm, yyyy HH:MM")
            }
            return result;
        },
        formatDate(date) {
            let isoFormat = null;
            let fullDate = null;
            let dateTime = null;
            let dayOfWeek = null;
            let shorterDate = null;
            if (date) {
                isoFormat = dateFormat(new Date(date), "dd-mm-yyyy");
                fullDate = dateFormat(new Date(date), "dddd, mmmm dS, yyyy")
                dateTime = dateFormat(new Date(date), "dddd, mmmm dS, yyyy HH:MM")
                dayOfWeek = dateFormat(new Date(date), "ddd")
                shorterDate = dateFormat(new Date(date), "mmm dS, yyyy")
            }
            return {isoFormat, fullDate, dateTime, dayOfWeek, shorterDate};
        },
        stringy(message, data) {
          console.log(message, JSON.parse(JSON.stringify(data)))
          return data
        },
    },
}
</script>

<style>

</style>